import { makeStyles } from '@material-ui/core'
import TableCell from '@material-ui/core/TableCell'
import { format } from 'date-fns'
import { shape } from 'prop-types'
import React from 'react'

import startupPropTypes from '../startupPropTypes'

const useStyles = makeStyles( {
  noMargin: {
    margin: 0,
  },
} )

const getMostRecentStage = ( stages ) => stages
  .map( ( { createdAt, updatedAt, ...rest } ) => ( {
    ...rest,
    updatedAt: new Date( updatedAt || createdAt ),
  } ) )
  .sort( ( stageA, stageB ) => stageA.updatedAt.getTime() - stageB.updatedAt.getTime() )
  .pop()
  .updatedAt

// Infer last updated from when a stage was updated
const LastUpdatedCell = ( { data: { stages } } ) => {
  const classes = useStyles()

  return (
    <TableCell align="right">
      <div>
        <p className={classes.noMargin}>{format( getMostRecentStage( stages ), 'dd/MM/yyyy' )}</p>
      </div>
    </TableCell>
  )
}

LastUpdatedCell.propTypes = {
  data: shape( startupPropTypes ).isRequired,
}

export default LastUpdatedCell
