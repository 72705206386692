import { EmptyMessage, IndustriesPieCard, MapCard, RaisesBarCard } from '@kaiku/ui'
import { Grid, makeStyles } from '@material-ui/core'
import { AccountTree, FlightTakeoff, Home, Store } from '@material-ui/icons'
import React, { useState } from 'react'
import useSWR from 'swr'

import { getStartupData } from '../../api'
import { getMoneyString, getShortCountryName as getCountryName, getSumFundraiseData } from './helper'
import NewStartupsCard from './Widgets/NewStartupsCard'
import StatCard from './Widgets/StatCard'

const geoUrl = '/world-countries.json'

const useStyles = makeStyles( {
  main: {
    height: 'calc(100vh - 200px)',
    padding: '0 5%',
    marginTop: '2.5%',
  },
} )

const Dashboard = () => {
  const classes = useStyles()
  const [ country, setCountry ] = useState( '' )
  const { data } = useSWR( getStartupData )

  const countryTitle = ( title ) => ( country === '' ? title : `${title} in ${getCountryName( country )}` )

  return (
    ( data && data.ALL.total !== 0 ) ? (
      <div className={classes.main}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={8} alignItems="stretch">
            <MapCard
              startupData={data}
              country={country}
              mapsvgpath={geoUrl}
              setCountry={setCountry}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <Grid container direction="column" spacing={1}>
              <Grid item xs={12}>
                <StatCard
                  title={countryTitle( 'Startups' )}
                  subtitle="Startups in this market"
                  value={country
                    ? data[ country ].startupCount
                    : ( data.GB || { startupCount: 0 } ).startupCount}
                  Icon={Store}
                />
              </Grid>
              <Grid item xs={12}>
                <StatCard
                  title="Total Startups"
                  subtitle="All Startups on the Platform"
                  value={data.ALL.total}
                  Icon={Home}
                />
              </Grid>
              <Grid item xs={12}>
                <StatCard
                  title={countryTitle( 'Total Fundraises' )}
                  subtitle="Total amount of fundraises in this market"
                  value={getMoneyString( country
                    ? getSumFundraiseData( data[ country ] ) : data.ALL.marketvalue )}
                  Icon={FlightTakeoff}
                />
              </Grid>
              <Grid item xs={12}>
                <StatCard
                  title={countryTitle( 'Matched Startups' )}
                  subtitle="Startups that you matched with"
                  value="-"
                  Icon={AccountTree}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={4} xs={12}>
            <RaisesBarCard title={countryTitle( 'Raises' )} startupData={country !== '' ? data[ country ] : data.ALL} />
          </Grid>
          <Grid item md={4} xs={12}>
            <IndustriesPieCard title={countryTitle( 'Industries' )} startupData={country !== '' ? data[ country ] : data.ALL} />
          </Grid>
          <Grid item md={4} xs={12}>
            <NewStartupsCard title="New Startups" startupData={data.ALL.hot} />
          </Grid>
        </Grid>
      </div>
    ) : <EmptyMessage> There are no startups in the database </EmptyMessage>
  )
}

export default Dashboard
