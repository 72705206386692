import { routes, withRouteParams } from '@kaiku/shared'
import { ExternalLink, HorizontalItems } from '@kaiku/ui'
import { Avatar, Box, makeStyles, Paper, Typography } from '@material-ui/core'
import { Business } from '@material-ui/icons'
import React from 'react'
import { useHistory } from 'react-router-dom'

import InvestorPropTypes from '../InvestorPropTypes'

const useStyles = makeStyles( ( { palette } ) => ( {
  root: {
    borderRadius: '100em',
    width: '95%',
    height: '5em',
    display: 'flex',
    cursor: 'pointer',
    boxShadow: '0 0 0.3em 0.025em rgba(0, 0, 0, 0.25)',
    margin: '0px 0px 5px 5px',
  },
  avatar: {
    margin: '-0.25em 0em 0em -1em',
    border: '4px solid #EEEEEE',
    borderRadius: '50%',
    height: '120%',
    width: '4.5em',
    backgroundColor: 'white',
  },
  content: {
    width: '100%',
    padding: '1em 1em 1.5em 1em',
  },
  name: {
    // fontSize: ( { companyName } ) => ( companyName.length > 30 ? '1.25em' : '1.5em' ),
    fontSize: '1.25em',
    maxWidth: '400px',
  },
  match: {
    marginLeft: 'auto',
    marginRight: '1.5em',
    fontSize: '1.25em',
    color: palette.primary.main,
  },
  industries: {
    textTransform: 'capitalize',
  },
  subheader: {
    justifyContent: 'flex-start',
    marginTop: 0,
    marginBottom: '0.5em',
  },
  oneLiner: {
    height: '3.125em',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    paddingRight: '1.5em',
    overflow: 'hidden',
  },
} ) )

const StartupResult = ( { id, representative } ) => {
  const { firstName, lastName, email, pictureUrl, website } = representative
  const classes = useStyles( { firstName } )

  const history = useHistory()
  const onClick = () => history.push( withRouteParams(
    routes.ADMIN_INVESTOR_PROFILE,
    { investorId: id },
  ) )

  return (
    <Paper className={classes.root} onClick={onClick} elevation={1}>

      <Avatar className={classes.avatar} src={pictureUrl} alt={firstName} imgProps={{ style: { objectFit: 'contain', width: '90%' } }}>
        <Business fontSize="large" />
      </Avatar>

      <Box className={classes.content}>

        <Box display="flex">
          <Typography className={classes.name} variant="h3">{`${firstName} ${lastName}`}</Typography>
        </Box>

        <HorizontalItems className={classes.subheader}>
          <Typography>{email}</Typography>
          {website && <ExternalLink href={website} />}
        </HorizontalItems>
      </Box>

    </Paper>
  )
}

StartupResult.propTypes = InvestorPropTypes.isRequired

export default StartupResult
