import { Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from '@material-ui/core'
import { bool, func, shape, string } from 'prop-types'
import React from 'react'

import investorPropTypes from '../InvestorPropTypes'

const useStyles = makeStyles( {
  actions: {
    justifyContent: 'space-between',
  },
} )

const ConfirmationDialog = ( { confirmData, onConfirm, onClose } ) => {
  const classes = useStyles()

  return (
    <Dialog open={!!confirmData} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>Onboard Investor</DialogTitle>

      <DialogContent>
        This investor has
        <strong> not </strong>
        verified their email. Onboarding this investor will give the
        representative access to all of our startup data, are you sure you want to onboard
        this investor?
      </DialogContent>

      <DialogActions className={classes.actions}>
        <Button onClick={onClose} variant="text">Cancel</Button>
        <Button onClick={() => onConfirm( confirmData )}>Confirm</Button>
      </DialogActions>
    </Dialog>
  )
}

ConfirmationDialog.propTypes = {
  confirmData: shape( {
    data: investorPropTypes,
    actionType: string.isRequired,
    confirmed: bool.isRequired,
  } ),
  onConfirm: func.isRequired,
  onClose: func.isRequired,

}
ConfirmationDialog.defaultProps = {
  confirmData: null,
}

export default ConfirmationDialog
