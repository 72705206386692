import { routes, withRouteParams } from '@kaiku/shared'
import { Avatar } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import { Business } from '@material-ui/icons'
import { shape } from 'prop-types'
import React from 'react'
import { useHistory } from 'react-router-dom'

import startupPropTypes from '../startupPropTypes'

const useStyles = makeStyles( ( { palette } ) => ( {
  logo: {
    width: 40,
    height: 40,
    objectFit: 'contain',
    borderRadius: '50%',
    borderWidth: '1px',
    marginRight: '15px',
    '& > svg': {
      fontSize: '1.25em',
    },
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    color: palette.primary.main,
    fontSize: '1.25em',
    textAlign: 'center',
  },
} ) )

const StartupCell = ( { data: { name, logoUrl, startupId } } ) => {
  const classes = useStyles()
  const history = useHistory()

  const openProfile = () => history.push( withRouteParams(
    routes.ADMIN_STARTUP_PROFILE,
    { startupId },
  ) )

  return (
    <TableCell scope="row">
      <div className={classes.rowContainer} onClick={openProfile}>
        <Avatar
          alt={`${name} logo`}
          className={classes.logo}
          src={logoUrl}
        >
          <Business fontSize="large" />
        </Avatar>
        {name}
      </div>
    </TableCell>
  )
}

StartupCell.propTypes = {
  data: shape( startupPropTypes ).isRequired,
}

export default StartupCell
