import { Button, CircularProgress, MenuItem, TableCell } from '@material-ui/core'
import { bindHover, bindMenu, usePopupState } from 'material-ui-popup-state/hooks'
import Menu from 'material-ui-popup-state/HoverMenu'
import { arrayOf, bool, func, shape, string } from 'prop-types'
import React from 'react'

import startupPropTypes from '../startupPropTypes'

const ActionCell = ( { data, completeAction, menuItems, loading, getDisplayText, ...props } ) => (
  <TableCell {...props} align="right">
    <ActionCellContent
      data={data}
      completeAction={completeAction}
      menuItems={menuItems}
      loading={loading}
      getDisplayText={getDisplayText}
    />
  </TableCell>
)

export const ActionCellContent = (
  { data, completeAction, menuItems, loading, getDisplayText },
) => {
  const popupState = usePopupState( { variant: 'popover', popupId: 'actions-menu' } )

  return (
    <>
      {loading ? <CircularProgress />
        : (
          <>
            <Button variant="text" {...bindHover( popupState )}>
              Actions
            </Button>

            <Menu {...bindMenu( popupState )}>
              {menuItems.map( ( actionType ) => (
                <MenuItem key={actionType} onClick={() => completeAction( { data, actionType } )}>
                  {getDisplayText( actionType )}
                </MenuItem>
              ) )}
            </Menu>
          </>
        )}
    </>
  )
}

const propTypes = {
  data: shape( startupPropTypes ).isRequired,
  completeAction: func.isRequired,
  menuItems: arrayOf( string ).isRequired,
  loading: bool,
  getDisplayText: func,
}

const defaultProps = {
  loading: false,
  getDisplayText: () => '',
}
ActionCell.propTypes = propTypes
ActionCell.defaultProps = defaultProps

ActionCellContent.propTypes = propTypes
ActionCellContent.defaultProps = defaultProps

export default ActionCell
