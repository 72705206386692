import { qs } from '@kaiku/shared'
import { useFetchAuth, usePaginatedFetch, useQuery } from '@kaiku/ui'
import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core'
import { useSnackbar } from 'notistack'
import { string } from 'prop-types'
import React, { useEffect, useState } from 'react'

import { getOnboardingStartupCompleteUrl, getOnboardingStartupsCompleteUrl, getOnboardingStartupsUrl, getRejectStartupsUrl, getRejectStartupUrl, getStartupVisibilityUrl } from '../../api'
import { ACTION_MENU_OPTIONS, TABLE_TYPES } from '../../lib/enums'
import ActionCell from './ActionCell'
import { IndustriesCell, LocationsCell, StagesCell } from './ExpandableChipCells'
import LastUpdatedCell from './LastUpdatedCell'
import StageDialog from './StageDialog'
import StartupCell from './StartupCell'

const useStyles = makeStyles( () => ( {
  tableRow: {
    height: '30px',
  },
  startupRowHidden: {
    backgroundColor: '#e7e7e7',
  },
} ) )

const dataUrls = {
  [ TABLE_TYPES.adminOnboarding ]: getOnboardingStartupsUrl,
  [ TABLE_TYPES.onboardingCompleted ]: getOnboardingStartupsCompleteUrl,
  [ TABLE_TYPES.rejected ]: getRejectStartupsUrl,
}

const MENU_ITEM_OPTIONS = {
  [ ACTION_MENU_OPTIONS.completeOnboarding ]: {
    getUrl: ( { startupId } ) => getOnboardingStartupCompleteUrl( startupId ),
    getBody: () => ( {} ),
    method: 'PUT',
    successMessage: ( { name } ) => `Onboarded ${name} successfully`,
    errorMessage: ( { name }, error ) => `Error onboarding ${name}. ${error.message}`,
    displayText: 'Complete Onboarding',
  },
  [ ACTION_MENU_OPTIONS.undoOnboarding ]: {
    getUrl: ( { startupId } ) => getOnboardingStartupCompleteUrl( startupId ),
    getBody: () => ( {} ),
    method: 'DELETE',
    successMessage: ( { name } ) => `Undo onboarding ${name} successfully`,
    errorMessage: ( { name }, error ) => `Error undo onboarding ${name}. ${error.message}`,
    displayText: 'Undo Onboarding',
  },
  [ ACTION_MENU_OPTIONS.reject ]: {
    getUrl: ( { startupId } ) => getRejectStartupUrl( startupId ),
    getBody: () => ( {} ),
    method: 'PUT',
    successMessage: ( { name } ) => `Rejected ${name} successfully`,
    errorMessage: ( { name }, error ) => `Error rejecting ${name}. ${error.message}`,
    displayText: 'Reject Startup',
  },
  [ ACTION_MENU_OPTIONS.unreject ]: {
    getUrl: ( { startupId } ) => getRejectStartupUrl( startupId ),
    getBody: () => ( {} ),
    method: 'DELETE',
    successMessage: ( { name } ) => `Unrejected ${name} successfully. The startup is now in 'NEW STARTUPS'.`,
    errorMessage: ( { name }, error ) => `Error unrejecting ${name}. ${error.message}`,
    displayText: 'Unreject Startup',
  },
  [ ACTION_MENU_OPTIONS.hideStartup ]: {
    getUrl: ( { startupId } ) => getStartupVisibilityUrl( startupId ),
    getBody: () => ( { visible: false } ),
    method: 'PUT',
    successMessage: ( { name } ) => `Hidden ${name} successfully`,
    errorMessage: ( { name }, error ) => `Error hiding ${name}. ${error.message}`,
    displayText: 'Hide Startup',
  },
  [ ACTION_MENU_OPTIONS.unhideStartup ]: {
    getUrl: ( { startupId } ) => getStartupVisibilityUrl( startupId ),
    getBody: () => ( { visible: true } ),
    method: 'PUT',
    successMessage: ( { name } ) => `Unhidden ${name} successfully`,
    errorMessage: ( { name }, error ) => `Error unhiding ${name}. ${error.message}`,
    displayText: 'Unhide Startup',
  },
}

const MENU_ITEMS = {
  [ TABLE_TYPES.adminOnboarding ]: () => [
    ACTION_MENU_OPTIONS.completeOnboarding,
    ACTION_MENU_OPTIONS.reject,
  ],
  [ TABLE_TYPES.onboardingCompleted ]: ( { visible } ) => [
    ACTION_MENU_OPTIONS.undoOnboarding,
    ACTION_MENU_OPTIONS.reject,
    visible ? ACTION_MENU_OPTIONS.hideStartup : ACTION_MENU_OPTIONS.unhideStartup,
  ],
  [ TABLE_TYPES.rejected ]: () => [
    ACTION_MENU_OPTIONS.unreject,
  ],
}

const PAGE_SIZES = [ 25, 50, 100, 200 ]

const StartupTable = ( { tableType } ) => {
  const [ selected, setSelected ] = useState()
  const [ actionLoading, setActionLoading ] = useState( false )
  const { enqueueSnackbar } = useSnackbar()
  const fetch = useFetchAuth()
  const classes = useStyles()

  const dataUrl = dataUrls[ tableType ]()

  const {
    query: { page = 0, size = PAGE_SIZES[ 0 ], stages = [] },
    updateQuery,
  } = useQuery()

  const { results = [], total = 0, setPage, mutate } = usePaginatedFetch(
    [ dataUrl, qs.stringify( { stages } ) ].join( '?' ),
    size,
  )

  const onStageDialogClose = () => {
    setSelected( null )
    mutate()
  }

  // Synchronise state with query parameter
  useEffect( () => {
    setPage( +page )
  }, [ page, setPage ] )

  const completeAction = ( { data, actionType } ) => {
    const { getUrl, method, successMessage,
      errorMessage, getBody } = MENU_ITEM_OPTIONS[ actionType ]

    setActionLoading( true )
    fetch( getUrl( data ), { method, body: getBody( data ) } )
      .then( () => {
        enqueueSnackbar( successMessage( data ) )
        mutate()
      } )
      .catch( ( error ) => {
        enqueueSnackbar( errorMessage( data, error ) )
      } )
      .finally( () => setActionLoading( false ) )
  }

  const columns = [
    [ 'Name', StartupCell ],
    [ 'Last Updated', LastUpdatedCell ],
    [ 'Locations', LocationsCell ],
    [ 'Industries', IndustriesCell ],
    [ 'Stages', ( props ) => <StagesCell {...props} onStageClick={setSelected} /> ],
    [ null, ( prop ) => (
      <ActionCell
        {...prop}
        loading={actionLoading}
        completeAction={completeAction}
        menuItems={MENU_ITEMS[ tableType ]( prop.data )}
        getDisplayText={( actionType ) => MENU_ITEM_OPTIONS[ actionType ].displayText}
      />
    ) ],
  ]

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow className={classes.tableRow}>
              {columns.map( ( [ header ], index ) => (
                <TableCell key={header || index} align="center">{header}</TableCell>
              ) )}
            </TableRow>
          </TableHead>

          <TableBody>
            {results.map( ( data ) => (
              <TableRow
                key={data.startupId}
                className={( data.visible === false ) ? classes.startupRowHidden : {}}
              >
                {columns.map( ( [ header, Cell ], index ) => (
                  <Cell key={header || index} data={data} />
                ) )}
              </TableRow>
            ) )}
          </TableBody>

          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={PAGE_SIZES}
                count={total}
                rowsPerPage={size}
                page={+page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onChangePage={( _, page ) => updateQuery( { page: +page } )}
                onChangeRowsPerPage={( { target: { value } } ) => updateQuery( { size: value } )}
                ActionsComponent={undefined}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>

      <StageDialog {...selected} onClose={onStageDialogClose} />
    </>
  )
}

StartupTable.propTypes = {
  tableType: string.isRequired,
}

export default StartupTable
