import { routes } from '@kaiku/shared'
import { SideNavbar } from '@kaiku/ui'
import { Container, makeStyles } from '@material-ui/core'
import React from 'react'
import { Route } from 'react-router-dom'

import InvestorTable from '../../components/InvestorTable'
import { TABLE_TYPES } from '../../lib/enums'

const useStyles = makeStyles( {
  container: {
    display: 'flex',
    flexGrow: 1,
    backgroundColor: '#F4F4F4',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '50px',
  },
} )

const INVESTOR_TABLES = [
  [ routes.ADMIN_ONBOARDING_INVESTOR_COMPLETED, TABLE_TYPES.onboardingCompleted ],
  [ routes.ADMIN_ONBOARDING_INVESTOR_REJECTED, TABLE_TYPES.rejected ],
  [ routes.ADMIN_ONBOARDING_INVESTOR_NEW, TABLE_TYPES.adminOnboarding ],
]

const sideNavItems = [
  { name: 'New Investors', route: routes.ADMIN_ONBOARDING_INVESTOR_NEW },
  { name: 'Completed', route: routes.ADMIN_ONBOARDING_INVESTOR_COMPLETED },
  { name: 'Rejected', route: routes.ADMIN_ONBOARDING_INVESTOR_REJECTED },
]

const InvestorsOnboarding = () => {
  const classes = useStyles()

  return (
    <>

      {INVESTOR_TABLES.map( ( [ path, tableType ] ) => (
        <Route
          key={path}
          path={path}
          render={() => (
            <Container className={classes.container} maxWidth={false}>

              <SideNavbar items={sideNavItems} />

              <Container className={classes.content} maxWidth={false}>
                <InvestorTable tableType={tableType} />
              </Container>

            </Container>
          )}
        />
      ) )}

    </>

  )
}

export default InvestorsOnboarding
