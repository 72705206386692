import { InvestorAboutBlock, InvestorAdditionalBlock, InvestorHeaderBlock, InvestorPortfolioBlock, InvestorThesisBlock } from '@kaiku/ui'
import { Box, colors, Container, Grid, makeStyles } from '@material-ui/core'
import React from 'react'
import { useParams } from 'react-router-dom'
import useSWR from 'swr'

import { getInvestorUrl } from '../../../api/investors'

const BLOCKS = [
  InvestorHeaderBlock,
  InvestorAboutBlock,
  InvestorThesisBlock,
  InvestorPortfolioBlock,
  InvestorAdditionalBlock,
]

const useStyles = makeStyles( {
  rightGutter: {
    position: 'sticky',
    top: '10em',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      marginBottom: '5em',
    },
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      marginBottom: '2.5em',
    },
  },
  ignore: {
    backgroundColor: colors.orange.A400,
  },
} )

const InvestorProfile = () => {
  const { investorId } = useParams()
  const { data } = useSWR( getInvestorUrl( investorId ) )

  const classes = useStyles()

  return (
    <Container maxWidth="lg" disableGutters>
      <Grid container justify="center" spacing={6}>
        <Grid item lg={9} md={8} xs={12}>
          <Container maxWidth="md">
            {BLOCKS
              .map( ( Block, index ) => (
                <Block
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  {...data}
                  notEditable
                />
              ) )}
          </Container>
        </Grid>

        <Grid item lg={2} md={3} xs={12}>
          <Box className={classes.rightGutter} />
        </Grid>
      </Grid>
    </Container>
  )
}

export default InvestorProfile
