import { handleAuthError, LoaderButton, ValidatedForm } from '@kaiku/ui'
import { Container, FormHelperText, Paper, Typography } from '@material-ui/core'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import { Field } from 'formik'
import { TextField } from 'formik-material-ui'
import React, { useState } from 'react'
import { object, string } from 'yup'

import { adminLogin } from '../../api'
import logo from '../../assets/Kaiku_normal.png'
import useCommonStyles from './styles'

const createSchema = () => object().shape( {
  email: string().email().required(),
  password: string().required(),
} )

const initialValues = { email: '', password: '' }

const Login = () => {
  const [ error, setError ] = useState()

  const onLoginSubmit = ( body ) => adminLogin( body )
    .then( () => signInWithEmailAndPassword( getAuth(), body.email, body.password ) )
    .catch( ( error ) => setError( handleAuthError( error ) ) )

  const classes = useCommonStyles()

  return (
    <Container className={classes.root}>
      <Container maxWidth="sm">
        <Paper className={classes.paper} elevation={10}>
          <img src={logo} className={classes.logo} alt="logo" />

          <Typography className={classes.header} variant="h3">Admin Login</Typography>

          <ValidatedForm
            initialValues={initialValues}
            schema={createSchema()}
            onSubmit={onLoginSubmit}
          >
            {( { isSubmitting, submitForm } ) => (
              <>
                <Field
                  component={TextField}
                  name="email"
                  label="Email"
                />

                <Field
                  component={TextField}
                  name="password"
                  label="Password"
                  type="password"
                />

                <FormHelperText className={classes.errorText} error>
                  {error}
                </FormHelperText>

                <LoaderButton
                  type="submit"
                  className={classes.mainButton}
                  loading={isSubmitting}
                  onClick={submitForm}
                >
                  Log in
                </LoaderButton>
              </>
            ) }
          </ValidatedForm>
        </Paper>

      </Container>
    </Container>
  )
}

export default Login
