const API_ROOT = '/api/onboarding'

export const getOnboardingStartupsUrl = () => `${API_ROOT}/startups`

export const getOnboardingStartupStagesUrl = ( startupId ) => `${API_ROOT}/startups/${startupId}`

export const getOnboardingStartupStageUrl = ( startupId ) => `${API_ROOT}/startups/${startupId}/stage`

export const getOnboardingStartupsCompleteUrl = () => `${API_ROOT}/startups/complete`

export const getOnboardingStartupCompleteUrl = ( startupId ) => `${API_ROOT}/startups/${startupId}/complete`

export const getRejectStartupsUrl = () => `${API_ROOT}/startups/reject`

export const getRejectStartupUrl = ( startupId ) => `${API_ROOT}/startups/${startupId}/reject`

export const getStartupVisibilityUrl = ( startupId ) => `${API_ROOT}/startups/${startupId}/visibility`

export const getNewInvestorsUrl = () => `${API_ROOT}/investors`

export const getOnboardedInvestorsUrl = () => `${API_ROOT}/investors/onboarded`

export const getRejectedInvestorsUrl = () => `${API_ROOT}/investors/rejected`

export const getOnboardInvestorUrl = ( investorId ) => `${API_ROOT}/investors/${investorId}/onboard`

export const getRejectInvestorUrl = ( investorId ) => `${API_ROOT}/investors/${investorId}/reject`
