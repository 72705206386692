export const TABLE_TYPES = {
  adminOnboarding: 'incomplete',
  onboardingCompleted: 'completed',
  rejected: 'rejected',
}

export const ACTION_MENU_OPTIONS = {
  completeOnboarding: 'completeOnboarding',
  undoOnboarding: 'undoOnboarding',
  reject: 'reject',
  unreject: 'unreject',
  hideStartup: 'hide',
  unhideStartup: 'unhide',
}
