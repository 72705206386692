import { ONBOARDING_STATUSES } from '@kaiku/shared'
import { CaptionField, getChangedData, ToggleButtonGroup, useFetchAuth, ValidatedForm } from '@kaiku/ui'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from '@material-ui/core'
import { ToggleButton } from '@material-ui/lab'
import merge from 'deepmerge'
import { FastField, Field } from 'formik'
import { TextField } from 'formik-material-ui'
import { mapValues } from 'lodash'
import { useSnackbar } from 'notistack'
import { func, shape, string } from 'prop-types'
import React from 'react'
import * as yup from 'yup'

import { getOnboardingStartupStageUrl } from '../../api'
import startupPropTypes from '../startupPropTypes'

const createSchema = () => yup.object().shape( {
  notes: yup.string(),
  status: yup.string(),
} )

const FIELDS = {
  notes: {
    label: 'Notes',
    tooltip: 'Notes about the progress of the stage',
  },
  status: {
    label: 'Status',
    tooltip: 'The status of the stage',
  },
}

const useStyles = makeStyles( {
  field: {
    justifyContent: 'center',
  },
} )

const Content = ( {
  data: { startupId, name, stages },
  stage,
  onClose,
} ) => {
  const stageData = stages.find( ( { stage: sourceStage } ) => sourceStage === stage )
  const { status, notes } = stageData

  const initialValues = mapValues(
    { status, notes },
    ( value ) => value || '',
  )

  const fetch = useFetchAuth()
  const { enqueueSnackbar } = useSnackbar()
  const schema = createSchema()

  const doSubmit = ( values ) => {
    const form = schema.submit( values )
    const updatedData = merge( stageData, form, { arrayMerge: ( _, x ) => x } )

    const patch = getChangedData( stageData, updatedData )

    if ( !patch ) {
      onClose()
      return
    }

    fetch( getOnboardingStartupStageUrl( startupId ), { method: 'PATCH', body: { stage, ...patch } } )
      .then( () => {
        enqueueSnackbar( `Updated onboarding details for ${name} successfully` )
        onClose()
      } )
      .catch( ( error ) => enqueueSnackbar( `Failed to update onboarding details for ${name}.\n${error.message}` ) )
  }

  const classes = useStyles()

  return (
    <ValidatedForm
      className={classes.form}
      initialValues={initialValues}
      schema={schema}
      onSubmit={doSubmit}
    >
      {( { submitForm } ) => (
        <>
          <DialogTitle>{`${stage} - ${name}`}</DialogTitle>

          <DialogContent>
            <CaptionField
              {...FIELDS.status}
              renderValue={() => (
                <Field
                  name="status"
                  className={classes.field}
                  component={ToggleButtonGroup}
                  exclusive
                  size="small"
                >
                  {Object.values( ONBOARDING_STATUSES ).map( ( status ) => (
                    <ToggleButton key={status} value={status}>
                      {status}
                    </ToggleButton>
                  ) )}
                </Field>
              )}
            />

            <CaptionField
              {...FIELDS.notes}
              renderValue={() => (
                <FastField
                  name="notes"
                  className={classes.field}
                  component={TextField}
                  multiline
                />
              )}
            />
          </DialogContent>

          <DialogActions>
            <Button onClick={onClose} variant="text">Cancel</Button>
            <Button onClick={submitForm} variant="text" autoFocus>Save</Button>
          </DialogActions>
        </>
      )}
    </ValidatedForm>
  )
}

Content.propTypes = {
  onClose: func,
  stage: string,
  data: shape( startupPropTypes ),
}

Content.defaultProps = {
  onClose: () => {},
  stage: null,
  data: null,
}

const StageDialog = ( props ) => {
  const { onClose, data, stage } = props

  const isOpen = [ stage, data ].every( ( x ) => x )

  return (
    <Dialog open={isOpen} onClose={() => onClose( false )} maxWidth="xs" fullWidth>
      {data && <Content {...props} />}
    </Dialog>
  )
}

StageDialog.propTypes = Content.propTypes
StageDialog.defaultProps = Content.defaultProps

export default StageDialog
