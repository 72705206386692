import { arrayOf, number, shape, string } from 'prop-types'

const propTypes = {
  startupId: string,
  name: string,
  logo: string,
  website: string,
  foundedYear: number,
  lastUpdatedAt: string,
  lastUpdatedBy: string,
  headquartersLocation: string,
  operationsLocations: arrayOf( string ),
  industries: arrayOf( string ),
  stages: arrayOf( shape( {
    stage: string,
    status: string,
    createdAt: string,
    updatedAt: string,
    notes: string,
  } ) ),
}

export default propTypes
