import { string } from 'prop-types'

const propTypes = {
  companyName: string,
  companyLogoUrl: string,
  type: string,
  companyWebsite: string,
}

export default propTypes
