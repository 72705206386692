import { useFetchAuth, usePaginatedFetch, useQuery } from '@kaiku/ui'
import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core'
import { Cancel, CheckCircle } from '@material-ui/icons'
import { useSnackbar } from 'notistack'
import { oneOf } from 'prop-types'
import React, { useEffect, useState } from 'react'

import { getNewInvestorsUrl, getOnboardedInvestorsUrl, getOnboardInvestorUrl, getRejectedInvestorsUrl, getRejectInvestorUrl } from '../../api'
import { ACTION_MENU_OPTIONS, TABLE_TYPES } from '../../lib/enums'
import { ActionCellContent } from '../StartupTable/ActionCell'
import ConfirmationDialog from './ConfirmationDialog'

const useStyles = makeStyles( ( { palette } ) => ( {
  tableRow: {
    height: '30px',
  },
  startupRowHidden: {
    backgroundColor: '#e7e7e7',
  },
  check: {
    color: palette.primary.main,
  },
  cross: {
    color: '#f44336',
  },
} ) )

const dataUrls = {
  [ TABLE_TYPES.adminOnboarding ]: getNewInvestorsUrl,
  [ TABLE_TYPES.onboardingCompleted ]: getOnboardedInvestorsUrl,
  [ TABLE_TYPES.rejected ]: getRejectedInvestorsUrl,
}

const MENU_ITEMS = {
  [ TABLE_TYPES.adminOnboarding ]: [
    ACTION_MENU_OPTIONS.completeOnboarding,
    ACTION_MENU_OPTIONS.reject,
  ],
  [ TABLE_TYPES.onboardingCompleted ]: [
    ACTION_MENU_OPTIONS.undoOnboarding,
    ACTION_MENU_OPTIONS.reject,
  ],
  [ TABLE_TYPES.rejected ]: [
    ACTION_MENU_OPTIONS.unreject,
  ],
}

const MENU_ITEM_OPTIONS = {
  [ ACTION_MENU_OPTIONS.completeOnboarding ]: {
    getUrl: ( { id } ) => getOnboardInvestorUrl( id ),
    getBody: () => ( {} ),
    method: 'PUT',
    successMessage: ( { representative: rep } ) => `Onboarded ${rep.firstName} ${rep.lastName} successfully`,
    errorMessage: ( { representative: rep }, error ) => `Error onboarding ${rep.firstName} ${rep.lastName}. ${error.message}`,
    displayText: 'Complete Onboarding',
  },
  [ ACTION_MENU_OPTIONS.undoOnboarding ]: {
    getUrl: ( { id } ) => getOnboardInvestorUrl( id ),
    getBody: () => ( {} ),
    method: 'DELETE',
    successMessage: ( { representative: rep } ) => `Undo onboarding ${rep.firstName} ${rep.lastName} successfully`,
    errorMessage: ( { representative: rep }, error ) => `Error undo onboarding ${rep.firstName} ${rep.lastName}. ${error.message}`,
    displayText: 'Undo Onboarding',
  },
  [ ACTION_MENU_OPTIONS.reject ]: {
    getUrl: ( { id } ) => getRejectInvestorUrl( id ),
    getBody: () => ( {} ),
    method: 'PUT',
    successMessage: ( { representative: rep } ) => `Rejected ${rep.firstName} ${rep.lastName} successfully`,
    errorMessage: ( { representative: rep }, error ) => `Error rejecting ${rep.firstName} ${rep.lastName}. ${error.message}`,
    displayText: 'Reject',
  },
  [ ACTION_MENU_OPTIONS.unreject ]: {
    getUrl: ( { id } ) => getRejectInvestorUrl( id ),
    getBody: () => ( {} ),
    method: 'DELETE',
    successMessage: ( { representative: rep } ) => `Unrejected ${rep.firstName} ${rep.lastName} successfully`,
    errorMessage: ( { representative: rep }, error ) => `Error unrejecting ${rep.firstName} ${rep.lastName}. ${error.message}`,
    displayText: 'Unreject',
  },
}

const PAGE_SIZES = [ 25, 50, 100, 200 ]

const InvestorsOnboarding = ( { tableType } ) => {
  const [ actionLoading, setActionLoading ] = useState( false )
  const [ showDialog, setShowDialog ] = useState()
  const { enqueueSnackbar } = useSnackbar()
  const fetch = useFetchAuth()
  const classes = useStyles()
  const {
    query: { page = 0, size = PAGE_SIZES[ 0 ] },
    updateQuery,
  } = useQuery()

  const { results = [], total = 0, setPage, mutate } = usePaginatedFetch(
    dataUrls[ tableType ](), size,
  )

  const completeAction = ( { data, actionType, confirmed } ) => {
    if ( actionType === ACTION_MENU_OPTIONS.completeOnboarding
      && !data.emailVerified
      && !confirmed ) {
      setShowDialog( { data, actionType, confirmed: true } )
      return
    }

    setShowDialog()

    const { getUrl, method, successMessage,
      errorMessage, getBody } = MENU_ITEM_OPTIONS[ actionType ]

    setActionLoading( true )
    fetch( getUrl( data ), { method, body: getBody( data ) } )
      .then( () => {
        enqueueSnackbar( successMessage( data ) )
        mutate()
      } )
      .catch( ( error ) => {
        enqueueSnackbar( errorMessage( data, error ) )
      } )
      .finally( () => setActionLoading( false ) )
  }

  const columns = [
    [ 'Company Name', ( { companyName } ) => companyName || '-' ],
    [ 'Representative Name', ( { representative: { firstName, lastName } } ) => `${firstName} ${lastName}` ],
    [ 'Email', ( { representative: { email } } ) => email ],
    [ 'Email Verified', ( { emailVerified } ) => ( emailVerified ? <CheckCircle className={classes.check} fontSize="large" /> : <Cancel className={classes.cross} fontSize="large" /> ) ],
    [ '', ( data ) => (
      <ActionCellContent
        loading={actionLoading}
        completeAction={completeAction}
        data={data}
        menuItems={MENU_ITEMS[ tableType ]}
        getDisplayText={( actionType ) => MENU_ITEM_OPTIONS[ actionType ].displayText}
      />
    ) ],
  ]

  // Synchronise state with query parameter
  useEffect( () => {
    setPage( +page )
  }, [ page, setPage ] )

  return (
    <TableContainer component={Paper}>

      <Table aria-label="simple table">
        <TableHead>
          <TableRow className={classes.tableRow}>
            {columns.map( ( [ column ] ) => (
              <TableCell key={column} align="center">{column}</TableCell>
            ) )}
          </TableRow>
        </TableHead>

        <TableBody>
          {results.map( ( investor ) => (
            <TableRow key={investor.id}>
              {columns.map( ( [ column, getCellData ] ) => (
                <TableCell key={column} align="center">{getCellData( investor )}</TableCell>
              ) )}
            </TableRow>
          ) )}
        </TableBody>

        <ConfirmationDialog
          confirmData={showDialog}
          onConfirm={completeAction}
          onClose={() => setShowDialog( false )}
        />

        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={PAGE_SIZES}
              count={total}
              rowsPerPage={size}
              page={+page}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              onChangePage={( _, page ) => updateQuery( { page: +page } )}
              onChangeRowsPerPage={( { target: { value } } ) => updateQuery( { size: value } )}
              ActionsComponent={undefined}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>

  )
}

InvestorsOnboarding.propTypes = {
  tableType: oneOf( Object.values( TABLE_TYPES ) ).isRequired,
}

export default InvestorsOnboarding
