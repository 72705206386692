import { getCountryName } from '@kaiku/shared'

export const getSumFundraiseData = ( countryData ) => {
  if ( countryData.length < 1 ) return []
  const numOr0 = ( n ) => ( Number.isNaN( n ) ? 0 : n )
  return Object.keys( countryData.fundraises )
    .map( ( key ) => countryData.fundraises[ key ] )
    .reduce( ( a, b ) => numOr0( a ) + numOr0( b ), 0 )
}

export const getShortCountryName = ( ISO_A2 ) => {
  if ( ISO_A2 === 'GB' ) return 'U.K'
  if ( ISO_A2 === 'US' ) return 'U.S'
  return getCountryName( ISO_A2 )
}

export const getMoneyString = ( number ) => {
  const money = new Intl.NumberFormat( 'en-UK',
    { style: 'currency', currency: 'GBP' } )
  return money.format( number )
}

