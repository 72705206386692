import { qs } from '@kaiku/shared'
import { useHistory, useLocation } from 'react-router-dom'

const useQuery = () => {
  const { search } = useLocation()
  const { push } = useHistory()

  const query = qs.parse( search, { ignoreQueryPrefix: true } )

  const setQuery = ( query ) => push( {
    search: qs.stringify( query, { encodeValuesOnly: true } ),
  } )

  const updateQuery = ( updatedQuery ) => setQuery( { ...query, ...updatedQuery } )

  return { query, setQuery, updateQuery }
}

export default useQuery
