import { MEETING_FILTERS, qs } from '@kaiku/shared'
import { usePaginatedFetch, useQuery } from '@kaiku/ui'
import { Button, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow } from '@material-ui/core'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'

import { getMeetingsUrl } from '../../api/engagements'

const columns = [ 'Investor', 'Startup', 'Status' ]

const useStyles = makeStyles( () => ( {
  tableRow: {
    height: '30px',
  },
  status: {
    textTransform: 'capitalize',
  },
} ) )

const InvestorCell = ( { data } ) => (
  <TableCell align="center">
    {data.representative.firstName}
  </TableCell>
)

InvestorCell.propTypes = {
  data: PropTypes.shape( {
    representative: PropTypes.shape( {
      firstName: PropTypes.string,
    } ),
  } ).isRequired,
}

const StartupCell = ( { data } ) => (
  <TableCell align="center">
    {data.name}
  </TableCell>
)

StartupCell.propTypes = {
  data: PropTypes.shape( {
    name: PropTypes.string,
  } ).isRequired,
}

const PAGE_SIZES = [ 25, 50, 100, 200 ]

const Meetings = () => {
  const classes = useStyles()

  const {
    query: { page = 0, size = PAGE_SIZES[ 0 ], filter = MEETING_FILTERS.all },
    updateQuery,
  } = useQuery()

  const {
    results: { investors, startups, meetings = [] } = {},
    total = 0, setPage,
  } = usePaginatedFetch(
    [ getMeetingsUrl(), qs.stringify( { filter } ) ].join( '?' ),
    size,
  )

  // Synchronise state with query parameter
  useEffect( () => {
    setPage( +page )
  }, [ page, setPage ] )

  return (
    <div>
      {Object.values( MEETING_FILTERS ).map( ( f ) => (
        <Button
          key={f}
          color={filter === f ? 'primary' : 'inherit'}
          onClick={() => updateQuery( { filter: f, page: 0 } )}
        >
          {f}
        </Button>
      ) )}
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow className={classes.tableRow}>
              {columns.map( ( header ) => (
                <TableCell key={header} align="center">{header}</TableCell>
              ) )}
            </TableRow>
          </TableHead>

          <TableBody>
            {meetings.map( ( { startupId, investorId, status } ) => (
              <TableRow
                key={`${investorId}-${startupId}`}
              >
                <InvestorCell data={investors[ investorId ]} />
                <StartupCell data={startups[ startupId ]} />
                <TableCell className={classes.status} align="center">{status}</TableCell>

              </TableRow>
            ) )}
          </TableBody>

          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={PAGE_SIZES}
                count={total}
                rowsPerPage={size}
                page={+page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onChangePage={( _, page ) => updateQuery( { page: +page } )}
                onChangeRowsPerPage={( { target: { value } } ) => updateQuery( { size: value } )}
                ActionsComponent={undefined}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  )
}

export default Meetings
