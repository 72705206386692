import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles( ( { palette, breakpoints } ) => ( {
  root: {
    background: `linear-gradient(bottom left, ${palette.primary.main}, ${palette.primary.dark})`,
    minWidth: '100%',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [ breakpoints.only( 'xs' ) ]: {
      padding: '0',
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
    '& > *': {
      margin: '10px',
    },
  },
  header: {
    textAlign: 'center',
  },
  mainButton: {
    marginTop: '15px',
  },
  errorText: {
    textAlign: 'center',
    fontSize: '16px',
  },
  logo: {
    objectFit: 'contain',
    height: '50px',
  },
} ) )

export default useStyles
