import { getRegionsXorCountries, ONBOARDING_STATUSES } from '@kaiku/shared'
import { ExpandableChip } from '@kaiku/ui'
import { colors, makeStyles, TableCell } from '@material-ui/core'
import clsx from 'clsx'
import { arrayOf, func, shape, string } from 'prop-types'
import React from 'react'

import startupPropTypes from '../startupPropTypes'

const useStyles = makeStyles( {
  chip: {
    marginRight: '0.75em',
  },
  statusInProgress: {
    backgroundColor: colors.blue[ 600 ],
  },
} )

const ExpandableChipsCell = ( { attribute = [] } ) => {
  const classes = useStyles()

  return (
    <TableCell align="left">
      {attribute.map( ( label ) => (
        <ExpandableChip key={label} className={classes.chip} label={label} />
      ) )}
    </TableCell>
  )
}

ExpandableChipsCell.propTypes = {
  attribute: arrayOf( string ).isRequired,
}

export const LocationsCell = ( {
  data: {
    operationsLocations = [],
    headquartersLocation,
  },
} ) => (
  <ExpandableChipsCell
    attribute={getRegionsXorCountries( [
      headquartersLocation,
      ...operationsLocations,
    ].filter( ( x ) => x ) )}
  />
)

LocationsCell.propTypes = {
  data: shape( startupPropTypes ).isRequired,
}

export const IndustriesCell = ( { data: { industries } } ) => (
  <ExpandableChipsCell attribute={industries} />
)

IndustriesCell.propTypes = {
  data: shape( startupPropTypes ).isRequired,
}

export const StagesCell = ( { onStageClick, data } ) => {
  const { stages } = data

  const classes = useStyles()

  return (
    <TableCell align="left">
      {stages.map( ( { stage, status } ) => (
        <ExpandableChip
          key={stage}
          className={clsx(
            classes.chip,
            status === ONBOARDING_STATUSES.inProgress && classes.statusInProgress,
          )}
          label={stage}
          tooltip={status}
          color={status !== ONBOARDING_STATUSES.notStarted ? 'primary' : 'default'}
          onClick={() => onStageClick( { data, stage } )}
        />
      ) )}
    </TableCell>
  )
}

StagesCell.propTypes = {
  data: shape( startupPropTypes ).isRequired,
  onStageClick: func,
}

StagesCell.defaultProps = {
  onStageClick: () => {},
}

