import { LoaderButton, useFetchAuth } from '@kaiku/ui'
import { Button, Container, Dialog, DialogActions, DialogTitle, FormHelperText, makeStyles, TextField } from '@material-ui/core'
import { bool, func } from 'prop-types'
import React, { useState } from 'react'

import { getReferralCodeUrl } from '../../api'

const useStyles = makeStyles( {
  container: {
    marginTop: '0.2em',
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      marginBottom: '0.1em',
    },
  },
} )

const CreateReferralDialog = ( { open, onClose, onCreate } ) => {
  const [ code, setCode ] = useState()
  const [ error, setError ] = useState()
  const [ submitting, setSubmitting ] = useState( false )
  const fetch = useFetchAuth()
  const [ description, setDescription ] = useState()
  const classes = useStyles()

  const createNew = () => {
    setError()
    setSubmitting( true )
    fetch( getReferralCodeUrl(), { method: 'PUT', body: { code, description } } )
      .then( onCreate )
      .catch( ( e ) => {
        setError( e.message )
      } )
      .finally( () => setSubmitting( false ) )
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Create new referral code</DialogTitle>

      <Container className={classes.container}>
        <span>Please enter a code and description.</span>
        <TextField label="Code" onChange={( e ) => setCode( e.target.value )} />
        <TextField label="Description" onChange={( e ) => setDescription( e.target.value )} />
        <FormHelperText error>
          {error}
        </FormHelperText>
      </Container>

      <DialogActions>
        <Button onClick={onClose} variant="text" color="primary">Cancel</Button>
        <LoaderButton loading={submitting} onClick={createNew} color="primary" disabled={!code || !description}>Create</LoaderButton>
      </DialogActions>

    </Dialog>
  )
}

CreateReferralDialog.propTypes = {
  open: bool,
  onClose: func.isRequired,
  onCreate: func.isRequired,
}

CreateReferralDialog.defaultProps = {
  open: false,
}

export default CreateReferralDialog
