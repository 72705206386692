import { ContentBlock } from '@kaiku/ui'
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { format } from 'date-fns'
import React from 'react'
import useSWR from 'swr'

import { getOnboardingStartupStagesUrl } from '../../../../api'
import StartupPropTypes from '../../../../components/startupPropTypes'

const useStyles = makeStyles( {
  stage: {
    textTransform: 'capitalize',
  },
  status: {
    textTransform: 'capitalize',
  },
} )

const OnboardingBlock = ( { id } ) => {
  const { data = [] } = useSWR( getOnboardingStartupStagesUrl( id ) )

  const classes = useStyles()

  return (
    <ContentBlock title="Onboarding">
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Stage</TableCell>
              <TableCell>Last Updated</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {data.map( ( { stage, status, updatedAt, createdAt } ) => (
              <TableRow key={stage}>
                <TableCell className={classes.stage}>{stage}</TableCell>
                <TableCell>{format( new Date( updatedAt || createdAt ), 'dd/MM/yyyy' )}</TableCell>
                <TableCell className={classes.status}>{status}</TableCell>
              </TableRow>
            ) )}
          </TableBody>
        </Table>
      </TableContainer>
    </ContentBlock>
  )
}

OnboardingBlock.propTypes = StartupPropTypes

export default OnboardingBlock
