import { routes } from '@kaiku/shared'
import { SideNavbar } from '@kaiku/ui'
import { Container, makeStyles } from '@material-ui/core'
import { Group, Inbox, Search, Star } from '@material-ui/icons'
import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import InvestorsGrid from './InvestorsGrid'
import Meetings from './Meetings'
import StartupGrid from './StartupGrid'

const useStyles = makeStyles( {
  container: {
    display: 'flex',
    width: '95%',
    flexGrow: 1,
    backgroundColor: '#F4F4F4',
  },
  grid: {
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '50px',
  },
} )

const ONBOARDING_ROUTES = [
  [ routes.ADMIN_BROWSE_INVESTORS, InvestorsGrid ],
  [ routes.ADMIN_BROWSE_MEETINGS, Meetings ],
  [ routes.ADMIN_BROWSE, StartupGrid ],
]

const SIDENAVIGATION_ITEMS = [
  {
    name: 'Startups',
    icon: Inbox,
    route: routes.ADMIN_BROWSE_STARTUPS,
  },
  {
    name: 'Investors',
    icon: Star,
    route: routes.ADMIN_BROWSE_INVESTORS,
  },
  {
    name: 'Meetings',
    icon: Group,
    route: routes.ADMIN_BROWSE_MEETINGS,
  },
  {
    name: 'Filter',
    icon: Search,
    route: routes.ADMIN_BROWSE_FILTER,
  },
]

const Onboarding = () => {
  const classes = useStyles()

  return (
    <Container className={classes.container} maxWidth={false}>
      <SideNavbar items={SIDENAVIGATION_ITEMS} />

      <Container className={classes.content} maxWidth={false}>
        <Switch>
          {ONBOARDING_ROUTES.map( ( [ path, Component ] ) => (
            <Route
              key={path}
              path={path}
              component={Component}
            />
          ) )}

          <Redirect to={routes.ADMIN_BROWSE_STARTUPS} />
        </Switch>
      </Container>
    </Container>
  )
}

export default Onboarding
