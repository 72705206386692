import { ONBOARDING_STAGES, routes } from '@kaiku/shared'
import { SideNavbar, useQuery } from '@kaiku/ui'
import { Container, makeStyles } from '@material-ui/core'
import React from 'react'
import { Route, useHistory, useLocation } from 'react-router-dom'

import StartupTable from '../../components/StartupTable'
import { TABLE_TYPES } from '../../lib/enums'

const useStyles = makeStyles( {
  container: {
    display: 'flex',
    flexGrow: 1,
    backgroundColor: '#F4F4F4',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '50px',
  },
} )

const STARTUP_STAGE_TOGGLES = [
  { name: 'Legal Vetting', stage: ONBOARDING_STAGES.legalVetting },
  { name: 'Business Vetting', stage: ONBOARDING_STAGES.businessVetting },
  { name: 'Awaiting Engagement', stage: ONBOARDING_STAGES.engagementLetter },
]

const STARTUP_TABLES = [
  [ routes.ADMIN_ONBOARDING_STARTUP_COMPLETED, TABLE_TYPES.onboardingCompleted ],
  [ routes.ADMIN_ONBOARDING_STARTUP_REJECTED, TABLE_TYPES.rejected ],
  [ routes.ADMIN_ONBOARDING_STARTUP_NEW, TABLE_TYPES.adminOnboarding ],
]

const StartupsOnboarding = () => {
  const classes = useStyles()

  const { pathname } = useLocation()
  const history = useHistory()
  const { query: { stages: currentStages = [] }, updateQuery } = useQuery()

  const ensureOnboardingUrl = ( fn ) => () => {
    if ( pathname !== routes.ADMIN_ONBOARDING_STARTUP_NEW ) {
      history.push( routes.ADMIN_ONBOARDING_STARTUP_NEW )
    }
    return fn()
  }

  const toggleStage = ( stage ) => updateQuery( {
    stages: currentStages.includes( stage )
      ? currentStages.filter( ( s ) => s !== stage )
      : [ ...currentStages, stage ],
  } )

  const sideNavItems = [
    {
      name: 'New Startups',
      color: !currentStages.length && pathname === routes.ADMIN_ONBOARDING_STARTUP_NEW ? 'primary' : 'inherit',
      onClick: ensureOnboardingUrl( () => updateQuery( { stages: [] } ) ),
    },
    ...STARTUP_STAGE_TOGGLES.map( ( { stage, ...rest } ) => ( {
      color: currentStages.includes( stage ) ? 'primary' : 'inherit',
      onClick: ensureOnboardingUrl( () => toggleStage( stage ) ),
      ...rest,
    } ) ),
    { name: 'Completed', route: routes.ADMIN_ONBOARDING_STARTUP_COMPLETED },
    { name: 'Rejected', route: routes.ADMIN_ONBOARDING_STARTUP_REJECTED },
  ]

  return (
    <>

      {STARTUP_TABLES.map( ( [ path, tableType ] ) => (
        <Route
          key={path}
          path={path}
          render={() => (
            <Container className={classes.container} maxWidth={false}>

              <SideNavbar items={sideNavItems} />

              <Container className={classes.content} maxWidth={false}>
                <StartupTable tableType={tableType} />
              </Container>

            </Container>
          )}
        />
      ) )}

    </>
  )
}

export default StartupsOnboarding
