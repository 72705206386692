import { routes } from '@kaiku/shared'
import { Navbar } from '@kaiku/ui'
import { AllInbox, Group, Search, Store } from '@material-ui/icons'
import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import Browse from './Browse'
import Onboarding from './Onboarding'
import Overview from './Overview'
import Partners from './Partners'
import InvestorProfile from './Profiles/Investor'
import StartupView from './Profiles/Startup'

const ROUTES = [
  [ routes.ADMIN_PARTNERS, Partners ],
  [ routes.ADMIN_ONBOARDING, Onboarding ],
  [ routes.ADMIN_OVERVIEW, Overview ],
  [ routes.ADMIN_BROWSE, Browse ],
  [ routes.ADMIN_STARTUP_PROFILE, StartupView ],
  [ routes.ADMIN_INVESTOR_PROFILE, InvestorProfile ],
]

const NAVIGATION_ITEMS = [
  [ 'Overview', routes.ADMIN_OVERVIEW, AllInbox ],
  [ 'Browse', routes.ADMIN_BROWSE, Search ],
  [ 'Onboard', routes.ADMIN_ONBOARDING, Store ],
  [ 'Partners', routes.ADMIN_PARTNERS, Group ],
]

const AVATAR_NAV_ITEMS = {
  nav: [],
  name: 'Admin',
  pictureUrl: null,
}

const AdminRoot = () => (
  <>
    <Navbar
      items={NAVIGATION_ITEMS}
      avatarItems={AVATAR_NAV_ITEMS}
    />

    <Switch>
      {ROUTES.map( ( [ path, Component ] ) => (
        <Route
          key={path}
          path={path}
          render={() => <Component />}
        />
      ) )}

      <Redirect to={routes.ADMIN_OVERVIEW} />
    </Switch>
  </>
)

export default AdminRoot
