import { routes } from '@kaiku/shared'
import { useUser } from '@kaiku/ui'
import { Box, makeStyles } from '@material-ui/core'
import React from 'react'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'

import withContexts from './lib/with-contexts'
import AdminRoot from './pages/Admin'
import Login from './pages/Login/Login'

const useStyles = makeStyles( {
  app: {
    minHeight: '100vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    margin: 0,
    left: 0,
    position: 'absolute',
    top: 0,
  },
} )

const App = () => {
  const classes = useStyles()

  const [ user ] = useUser()

  if ( user === false ) {
    return ( <div /> )
  }

  return (
    <Box className={classes.app}>
      <Router>
        <Switch>
          {user
            ? <Route path={routes.ADMIN_HOME} component={AdminRoot} />
            : <Route path={routes.ADMIN_LOGIN} component={Login} /> }

          <Redirect to={user ? routes.ADMIN_OVERVIEW : routes.ADMIN_LOGIN} />
        </Switch>
      </Router>
    </Box>
  )
}

export default () => withContexts( <App /> )
