import { routes, withRouteParams } from '@kaiku/shared'
import { StartupResult } from '@kaiku/ui'
import { Grid } from '@material-ui/core'
import React from 'react'
import { useHistory } from 'react-router-dom'
import useSWR from 'swr'

import { getPlatformStartupsUrl } from '../../api'

const StartupGrid = () => {
  const { data = [] } = useSWR( getPlatformStartupsUrl() )

  const history = useHistory()

  const onClick = ( startupId ) => () => history.push( withRouteParams(
    routes.ADMIN_STARTUP_PROFILE,
    { startupId },
  ) )

  return (
    <Grid container spacing={2}>
      {data.map( ( startup ) => (
        <Grid key={startup.id} item xs={6}>
          <StartupResult {...startup} mini onClick={onClick( startup.id )} />
        </Grid>
      ) )}
    </Grid>
  )
}

export default StartupGrid
