import { Grid } from '@material-ui/core'
import React from 'react'
import useSWR from 'swr'

import { getInvestorsUrl } from '../../api'
import InvestorBrowseResult from '../../components/InvestorBrowseResult'

const InvestorsGrid = () => {
  const { data = [] } = useSWR( getInvestorsUrl() )

  return (
    <Grid container spacing={2}>
      {data.map( ( investor ) => (
        <Grid key={investor.id} item xs={6}>
          <InvestorBrowseResult {...investor} />
        </Grid>
      ) )}
    </Grid>
  )
}

export default InvestorsGrid
