import { Button, CircularProgress, MenuItem, TableCell } from '@material-ui/core'
import { bindHover, bindMenu, usePopupState } from 'material-ui-popup-state/hooks'
import Menu from 'material-ui-popup-state/HoverMenu'
import { arrayOf, bool, func, shape, string } from 'prop-types'
import React from 'react'

const ReferralActionCell = ( { loading, menuItems, code, description } ) => {
  const popupState = usePopupState( { variant: 'popover', popupId: 'actions-menu' } )

  return (
    <TableCell align="right">
      {loading ? <CircularProgress />
        : (
          <>
            <Button variant="text" {...bindHover( popupState )}>
              Actions
            </Button>

            <Menu {...bindMenu( popupState )}>
              {menuItems.map( ( { label, doAction } ) => (
                <MenuItem key={label} onClick={() => doAction( code, description )}>
                  {label}
                </MenuItem>
              ) )}
            </Menu>
          </>
        )}
    </TableCell>
  )
}

ReferralActionCell.propTypes = {
  loading: bool,
  menuItems: arrayOf( shape( {
    label: string.isRequired,
    doAction: func.isRequired,
  } ) ).isRequired,
  code: string.isRequired,
  description: string.isRequired,
}

ReferralActionCell.defaultProps = {
  loading: false,
}

export default ReferralActionCell
