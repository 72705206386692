import { intialiseFirebaseAuth, setupYup } from '@kaiku/ui'
import * as Sentry from '@sentry/react'
import SentryRRWeb from '@sentry/rrweb'
import { Integrations } from '@sentry/tracing'

import { ENVIRONMENT, FIREBASE_AUTH_DOMAIN, FIREBASE_AUTH_EMULATOR_HOST, FIREBASE_WEB_API_KEY, IS_DEVELOPMENT, SENTRY_DSN } from './consts'

setupYup()
intialiseFirebaseAuth( {
  emulator: FIREBASE_AUTH_EMULATOR_HOST,
  apiKey: FIREBASE_WEB_API_KEY,
  authDomain: FIREBASE_AUTH_DOMAIN,
} )

// Non-local development services
if ( !IS_DEVELOPMENT ) {
  Sentry.init( {
    environment: ENVIRONMENT,
    dsn: SENTRY_DSN,
    autoSessionTracking: true,
    integrations: [
      new Integrations.BrowserTracing(),
      new SentryRRWeb(),
    ],
    tracesSampleRate: 1.0,
  } )
}
