import { routes } from '@kaiku/shared'
import { Container, makeStyles, Tab, Tabs } from '@material-ui/core'
import React from 'react'
import { Redirect, Switch, useHistory, useLocation } from 'react-router-dom'

import InvestorsOnboarding from './investors'
import StartupsOnboarding from './startups'

const useStyles = makeStyles( {
  container: {
    display: 'flex',
    flexGrow: 1,
    backgroundColor: '#F4F4F4',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '50px',
  },
} )

const NAVIGATION_ITEMS = [
  [ 'Startup', routes.ADMIN_ONBOARDING_STARTUP_NEW ],
  [ 'Investor', routes.ADMIN_ONBOARDING_INVESTOR_NEW ],
]

const Onboarding = () => {
  const classes = useStyles()

  const { pathname } = useLocation()
  const history = useHistory()

  return (
    <Container maxWidth={false}>

      <Tabs
        value={pathname.includes( routes.ADMIN_ONBOARDING_INVESTOR_ROOT ) ? 1 : 0}
        centered
      >
        {NAVIGATION_ITEMS.map( ( [ name, destination ] ) => (
          <Tab
            key={destination}
            className={classes.tab}
            label={name}
            onClick={() => history.push( destination )}
          />
        ) )}
      </Tabs>
      <Container className={classes.container} maxWidth={false}>

        <Switch>
          <>

            <StartupsOnboarding />

            <InvestorsOnboarding />

            <Redirect to={pathname.includes( routes.ADMIN_ONBOARDING_INVESTOR_ROOT )
              ? routes.ADMIN_ONBOARDING_INVESTOR_NEW
              : routes.ADMIN_ONBOARDING_STARTUP_NEW}
            />
          </>

        </Switch>
      </Container>
    </Container>

  )
}

export default Onboarding
