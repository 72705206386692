import { BackButton, StartupAboutBlock, StartupBusinessBlock, StartupFundraiseBlock, StartupHeaderBlock, StartupLegalsBlock, StartupTeamBlock } from '@kaiku/ui'
import { Box, colors, Container, Grid, makeStyles } from '@material-ui/core'
import React from 'react'
import { useParams } from 'react-router-dom'
import useSWR from 'swr'

import { getStartupUrl } from '../../../api/startups'
import OnboardingBlock from './Blocks/OnboardingBlock'

const BLOCKS = [
  StartupHeaderBlock,
  OnboardingBlock,
  StartupAboutBlock,
  StartupBusinessBlock,
  StartupLegalsBlock,
  StartupFundraiseBlock,
  StartupTeamBlock,
]

const useStyles = makeStyles( {
  gutter: {
    position: 'sticky',
    top: '10em',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      marginBottom: '5em',
    },
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      marginBottom: '2.5em',
    },
  },
  ignore: {
    backgroundColor: colors.orange.A400,
  },
  backButton: {
    marginTop: '10px',
  },
} )

const StartupView = () => {
  const { startupId } = useParams()
  const { data } = useSWR( getStartupUrl( startupId ) )

  const classes = useStyles()

  return (
    <Container maxWidth="lg" disableGutters>
      <Grid container justify="center" spacing={6}>
        <Grid item lg={1} md={3} xs={12}>
          <Box className={classes.gutter}>
            <BackButton className={classes.backButton} />
          </Box>
        </Grid>
        <Grid item lg={9} md={8} xs={12}>
          <Container maxWidth="md">
            {BLOCKS
              .map( ( Block, index ) => (
                <Block
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  {...data}
                  notEditable
                />
              ) )}
          </Container>
        </Grid>

        <Grid item lg={1} md={3} xs={12}>
          <Box className={classes.gutter} />
        </Grid>
      </Grid>
    </Container>
  )
}

export default StartupView
