import { useFetchAuth, usePaginatedFetch, useQuery } from '@kaiku/ui'
import { Container, IconButton, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'

import { getReferralCodeUrl } from '../../api'
import CreateReferralDialog from './CreateReferralDialog'
import ReferralActionCell from './ReferralActionCell'
import UpdateReferralDialog from './UpdateReferralDialog'

const useStyles = makeStyles( {
  container: {
    marginTop: '2em',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '50px',
  },
} )

const PAGE_SIZES = [ 25, 50, 100, 200 ]

const columns = [
  [ 'Referral Code' ],
  [ 'Description' ],
]

const Partners = () => {
  const classes = useStyles()
  const [ openCreateNewDialog, setOpenCreateNewDialog ] = useState( false )
  const [ openEditDialog, setOpenEditDialog ] = useState()
  const { enqueueSnackbar } = useSnackbar()
  const fetch = useFetchAuth()
  const [ loading, setLoading ] = useState()

  const {
    query: { page = 0, size = PAGE_SIZES[ 0 ] },
    updateQuery,
  } = useQuery()

  const { results = [], total = 0, setPage, mutate } = usePaginatedFetch(
    getReferralCodeUrl(),
    size,
  )

  const onFinishAction = () => {
    mutate()
    setOpenCreateNewDialog( false )
    setOpenEditDialog()
  }

  const deleteReferralCode = ( code ) => {
    setLoading( true )
    fetch( getReferralCodeUrl(), { method: 'DELETE', body: { code } } )
      .then( mutate )
      .catch( () => enqueueSnackbar( 'Failed to delete referral code.', { variant: 'error' } ) )
      .finally( () => setLoading( false ) )
  }

  const MENU_OPTIONS = [
    {
      label: 'edit description',
      doAction: ( code, description ) => setOpenEditDialog( { code, currentDesc: description } ),
    },
    {
      label: 'delete',
      doAction: deleteReferralCode,
    },
  ]

  // Synchronise state with query parameter
  useEffect( () => {
    setPage( +page )
  }, [ page, setPage ] )

  return (
    <Container maxWidth="md" className={classes.container}>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow className={classes.tableRow}>
              {columns.map( ( [ header ], index ) => (
                <TableCell key={header || index} align="center">{header}</TableCell>
              ) )}
              <TableCell align="right">
                <IconButton
                  className={classes.margin}
                  onClick={() => setOpenCreateNewDialog( true )}
                >
                  <Add color="primary" />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {results.map( ( { code, description } ) => (
              <TableRow
                key={code}
              >
                <TableCell align="center">{code}</TableCell>
                <TableCell align="center">{description}</TableCell>
                <ReferralActionCell
                  loading={loading}
                  menuItems={MENU_OPTIONS}
                  code={code}
                  description={description}
                />
              </TableRow>
            ) )}
          </TableBody>

          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={PAGE_SIZES}
                count={total}
                rowsPerPage={size}
                page={+page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onChangePage={( _, page ) => updateQuery( { page: +page } )}
                onChangeRowsPerPage={( { target: { value } } ) => updateQuery( { size: value } )}
                ActionsComponent={undefined}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>

      <CreateReferralDialog
        open={openCreateNewDialog}
        onClose={() => setOpenCreateNewDialog( false )}
        onCreate={onFinishAction}
      />
      <UpdateReferralDialog
        open={!!openEditDialog}
        onClose={() => setOpenEditDialog( false )}
        onEdit={onFinishAction}
        {...openEditDialog}
      />
    </Container>

  )
}

export default Partners
